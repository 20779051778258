.transitionFile {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    padding: 0 !important;
    margin: 0 !important ;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
}

